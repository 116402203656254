html {
  font-family: sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: #273552;
}

.resumebox {
  background: #222;
  width: 98%;
  margin: 80px auto 50px;
  font-family: "Inconsolata", monospace;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.resumebox:before {
  border-radius: 6px 6px 0 0;
  content: "";
  background: #1a1a1a;
  width: 100%;
  display: block;
  height: 20px;
  position: relative;
  top: -4px;
}
.resumebox:after {
  border-radius: 0 0 6px 6px;
  content: "";
  background: #1a1a1a;
  width: 100%;
  display: block;
  height: 20px;
  position: relative;
  bottom: -4px;
}
.resumebox h3 {
  font-family: "Inconsolata", monospace;
  font-weight: 400;
}

.resumebox h4 {
  font-family: "Inconsolata", monospace;
  font-weight: 400;
  color: #aaa;
  margin: 50px 0 20px;
}
.resumebox h5 {
  font-family: "Inconsolata", monospace;
  font-weight: 400;
  color: #aaa;
  margin: 40px 0 6px;
}
.time {
  margin-bottom: 10px;
  display: block;
  font-size: 13px;
  color: #aaa;
}
.resume-inside {
  padding: 16px 20px 16px;
  color: #fff;
  text-align: left;
}
ul {
  margin: 0;
}
li {
  margin-bottom: 15px;
  color: #eee;
}

.title {
  color: #fff;
}

.title h1 {
  font-weight: 300;
}

.text {
  text-align: left;
  color: #fff;
  font-size: 22px;
  font-weight: 300;
}

.aboutpage {
  background: #273552;
}

.post {
  display: grid;
  max-width: 1000px;
  margin: 100px auto;
  grid-gap: 10px 50px;
  grid-template-columns: 1fr 18fr 1fr 1fr;
}

.post > * {
  grid-column: 2 / -2;
}

.resume {
  grid-column: 1/-1;
}

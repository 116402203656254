.contactpost {
  color: #ffffff;
  display: grid;
  max-width: 1000px;
  margin: 150px auto;
  grid-gap: 10px 50px;
  grid-template-columns: 1fr 18fr 1fr;
}

.contactpost > * {
  grid-column: 2 / -2;
}

.contactpost p {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
}
